'use client';
import { studentLinks, tutorLinks, visitorLinks } from '../../../constants'
import { useLogout, useRouterContext, useTranslate } from "@refinedev/core";
import { useNavigate, useLocation } from 'react-router-dom'
//import { usePathname } from 'next/navigation'
import React from 'react'
import { Logout } from '@mui/icons-material';
import { useAppContext } from 'contexts/AppContext';

const NavItems = () => {
  const { Link } = useRouterContext();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: true
  });
  const t = useTranslate();
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();
  const isTutor = currentTutorData !== null;
  const linksToUse = loggedIn
  ? isTutor
    ? tutorLinks
    : studentLinks
  : visitorLinks;

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    mutateLogout();
    setLoggedIn(false)
  }

  return (
    <ul className="flex md:flex-row items-center justify-center gap-5"> {/* Updated className */}
      {linksToUse.map((link) => {
        const isActive = location.pathname === link.route;

        return (
          <li
            key={link.route}
            className={`${
              isActive && 'text-white'
            } flex-center p-medium-16 whitespace-nowrap`}
          >
            <a href={link.route} className="text-white">{link.label}</a>
          </li>
        );
      })}

      {loggedIn ? 
      <>
      {currentUserData?.name && (
          <p className="text-sm text-white font-semibold">
            {currentUserData?.name}
          </p>
        )}

        {currentUserData?.avatar && (
          <img
            src={currentUserData?.avatar}
            alt={currentUserData?.name}
            className="w-8 h-8 rounded-full"
          />
        )}

      <button
        key="logout"
        onClick={() => handleLogout()}
        className="flex items-center p-medium-16 whitespace-nowrap cursor-pointer"
      >
        <div className="flex items-center justify-center  text-white">
          <div>
           <Logout/>
          </div>
        </div>
      </button> </>
      : null }
    </ul>
  );
}

export default NavItems;

import { Link, useNavigate } from "react-router-dom";
import { TutorCardProp, Subject } from 'interfaces/tutor';
import { useAppContext } from 'contexts/AppContext';
import { Button } from 'components/ui/button';
import { useState } from "react";
import  StarRating  from 'components/tutor/StarRating'

const TutorCard = ({ id, firstName, lastName, email, subjects, avatar, description, isActive, tutor, rating }: TutorCardProp) => {
  const { currentUserData } = useAppContext();
  const subjectsArrayOfObjects: Subject[] = subjects; 

  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState<Subject>({ name: '', price: '' });

  const generateLink = () => {
    if (currentUserData?.email === email) return `/my-profile`;
    return `/book-a-tutor/show/${id}`;
  };

  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');

    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }

    return text;
  };

  const handleClick = (t: any, isImmediate: boolean) => {
    const state = { tutorToBeBooked: t, isImmediate, selectedSubject };
    navigate(`/booking/create/${t._id}`, { state });
  };

  const handleSubjectSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedSubject = event.target.value;
    console.log("newSelectedSubject", newSelectedSubject)
    const foundSubject = tutor.subjects.find((subject) => subject.name === newSelectedSubject);
    if (foundSubject) {
      setSelectedSubject(foundSubject);
    }
  };

  return (
    <div className="flex items-start p-4 hover:shadow-md transition-all duration-300 w-full border border-gray-300 rounded-md bg-gray-200">
      {/* Column 1: Avatar */}
      <div className="flex flex-col items-center">
        <img src={avatar} alt="Profile Picture" className="rounded-md object-cover w-24 h-24" />
        <span className={`mt-2 ${isActive ? 'text-green-500' : 'text-red-500'}`}>
          {isActive ? (
            <>
              Active
              &#10004;
            </>
          ) : (
            <>
              Inactive
              &#10006;
            </>
          )}
        </span>
     </div>
      {/* Column 2: Name, Description, Subjects */}
      <div className="flex flex-col justify-between mr-4 ml-4 w-3/5"> {/* Adjust the width as needed */}
        {/* Name */}
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-blue-900">
            <Link to={generateLink()}>{firstName} {lastName}</Link>
          </h2>

        </div>
  
        {/* Description */}
        <p className="text-sm text-gray-600 min-h-[40px] max-w-[75%]">{truncateText(description, window.innerWidth < 768 ? 12 : 35)}</p>

        {/* Star Rating */}
        <div className="flex items-center mt-3">
          <StarRating rating={rating} numberOfReviews={4} /> {/* Pass the actual rating here */}
        </div>

      </div>
  
      {/* Column 3: SelectSubject */}
      <div className="flex flex-col">
        {/* Select Subject dropdown */}
        <label htmlFor={`subjectSelect-${id}`} className="text-sm text-gray-600 mb-1">
          Select Subject:
        </label>
        <select id={`subjectSelect-${id}`} value={selectedSubject.name}  className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none mb-4" onChange={handleSubjectSelect}>
          <option value="" style={{ fontSize: '1rem', height: '1.5rem' }}></option>
          {subjectsArrayOfObjects.map((subject, index) => (
            <option key={index} value={subject.name}>{subject.name} - ${subject.price}/hr</option>
          ))}
        </select>

        {/* Buttons with space */}
        <div className="flex flex-col items-center md:flex-row md:justify-center md:gap-4 space-y-2">
          <Button
            variant="default"
            size="default"
            className="bg-primary text-primary-foreground hover:bg-primary/90"
            onClick={() => handleClick(tutor, false)}
            disabled={false}
          >
            Schedule Tutor
          </Button>
          <Button
            variant="default"
            size="default"
            className={tutor.isActive ? 'bg-primary text-primary-foreground hover:bg-primary/90' : 'bg-ccc text-666'}
            onClick={() => tutor.isActive && handleClick(tutor, true)}
            disabled={!tutor.isActive}
          >
            Instant Booking
          </Button>
      </div>
    </div>
  </div>
  );
};

export default TutorCard;

export const tutorLinks = [
    {
      label: 'Dashboard',
      route: '/',
    },
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
  ]

  export const studentLinks = [
    {
      label: 'Dashboard',
      route: '/',
    },
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
    {
      label: 'Tutor Application',
      route: '/tutor-application',
    },
  ]

  export const visitorLinks = [
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
    {
      label: 'Tutor Application',
      route: '/tutor-application',
    },
    {
      label: 'Sign In',
      route: '/sign-in'
    }
  ]
  
  
  export const eventDefaultValues = {
    title: '',
    description: '',
    location: '',
    imageUrl: '',
    startDateTime: new Date(),
    endDateTime: new Date(),
    categoryId: '',
    price: '',
    isFree: false,
    url: '',
  }
  
import { useState } from "react";
import { useDelete, useGetIdentity } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";

import Form from 'components/common/Form';

const TutorApplication = () => {
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true
  });
  const [resume, setResume] = useState({name: '', url: ''});
  const { refineCore: {onFinish, formLoading }, register, handleSubmit} = useForm();

  const {mutate} = useDelete();
  const handleResumeChange = (file: File) => {
    const reader = (readFile: File) => new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(readFile);
    });

    reader(file).then((result: string) => setResume({ name: file?.name, url: result }));
  };
  const onFinishHandler = async (data: FieldValues) => {
    if(!resume.name) return alert('Please upload a resume');
    const selectedNames = data.subjects
    .filter((subject: { selected: any; }) => subject.selected)
    .map((selectedSubject: { name: any; }) => selectedSubject.name);

    const selectedPrices = data.subjects
      .filter((subject: { selected: any; }) => subject.selected)
      .map((selectedSubject: { price: any; }) => selectedSubject.price);

    const namesString = selectedNames.join(' ');
    const pricesString = selectedPrices.join(' ');

    if (namesString && pricesString) {
      console.log('Selected Names:', namesString);
      console.log('Selected Prices:', pricesString);
    } else {
      return alert('No selected subjects.');
    }
    //Create the subjects object
    const subjectsArrayOfObjects = createSubjectsArrayOfObjects(namesString, pricesString)
    data.subjects = subjectsArrayOfObjects;

    await onFinish({ ...data, resume: resume.url, userId: user.userid,  })
    console.log("here is data", data);
    console.log("here is user data", user );

    const getStudentRequest = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/students/${user.userid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    const student = await getStudentRequest.json();
     
    const schedule = [{}];

    //Automatically create tutor on application submit
    const createTutor = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/tutors`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: user.userid,
            subjects: subjectsArrayOfObjects,
            //prices: data.baseRate.split(" "),
            rating: 5.0,
            tutorDescription: data.description,
            isActive: false,
            schedule: schedule
          })
        })

    const tutor = await createTutor.json();

    console.log("student data is", student );
    
    mutate({
          resource: "students",
          id: student._id
      },
      {
          onSuccess: () => {
              console.log("sucess");
          },
      },
  );

    /*if(createTutor.status === 200)
      {
        localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          avatar: user.picture,
          userid: data._id
        })
      );
    }   
    else{
      return Promise.reject()
    }*/
  }
    

  function createSubjectsArrayOfObjects(subjects : string, prices : string) {
    const subjectsArray = subjects.split(" ");
    const pricesArray = prices.split(" ")

    if (subjectsArray.length !== pricesArray.length) {
      throw new Error('Arrays must have the same length');
    }

    const result = [];

    for (let i = 0; i < subjectsArray.length; i++) {
      const obj = {
        name: subjectsArray[i],
        price: pricesArray[i],
      };
      result.push(obj);
    }
  
    return result;
  }

  return (
    <Form
      type="Create"
      register={register}
      onFinish={onFinish}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      handleResumeChange={handleResumeChange}
      onFinishHandler={onFinishHandler}
      resume={resume}
    />
         
  )
}

export default TutorApplication
import { useUpdate } from "@refinedev/core";
import { useAppContext } from 'contexts/AppContext';
import { makeValidJSON } from 'functions/common';
import React, { useEffect, useState } from 'react'
import { ScheduleMeeting, TimeSlot } from 'react-schedule-meeting';
import Availability from './Availability';

function SetSchedule(this: any) {

  const [addDaysOfWeek, setAddDaysOfWeek] = useState([false, false, false, false, false, false, false]);
  const [removeDaysOfWeek, setRemoveDaysOfWeek] = useState([false, false, false, false, false, false, false]);

  //States for Add Schedule input
  const [addStartTime, setAddStartTime] = useState('');
  const [addEndTime, setAddEndTime] = useState('');
  
  const [startDates, setStartDates] = useState('');

  const [addStartDate, setAddStartDate] = useState('');
  const [addEndDate, setAddEndDate] = useState('');

  const handleStartDateChange = (event: any) => {setStartDates(event.target.value)}

  //States for Remove Schedule input
  const [removeStartTime, setRemoveStartTime] = useState('');
  const [removeEndTime, setRemoveEndTime] = useState('');
  const [removeStartDate, setRemoveStartDate] = useState('');
  const [removeEndDate, setRemoveEndDate] = useState('');

  //States brought in
  const { currentTutorData, setCurrentTutorData } = useAppContext();
  const { mutate } = useUpdate();

  //For Showing Schedule Calendar
    const [showCustomComponent, setShowCustomComponent] = useState(false);
    const toggleCustomComponent = () => {
      setShowCustomComponent(!showCustomComponent);
    };

    const [currentSchedule] = useState(currentTutorData.schedule.length!==0 ? currentTutorData.schedule : []);
    //const isoStringSchedule = JSON.parse(currentTutorData.schedule)
    const [dateObjectSchedule, setDateObjectSchedule] = useState(currentSchedule.length!==0 ? parseDatesInObjects(currentSchedule) : []);
    //const sortedScheduleDescending = makeValidJSON(currentTutorData.schedule).sort((a: { startTime: Date; }, b: { startTime: Date; }) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

    //***********const modified = modifiedTimezone(copyOfSchedule)
    //console.log("modified", modified)
    // Use useEffect to sort the schedule whenever it changes
    useEffect(() => {
      //const sortedSchedule = [...currentSchedule].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
      //setCurrentSchedule(sortedSchedule);
    }, [dateObjectSchedule]);

    //Take existing and new data and update Tutor
    const handleAddClick = () => {
        const newSchedule = generateSchedule(addDaysOfWeek, addStartTime, addEndTime, addStartDate, addEndDate)
        const tempTutor = currentTutorData;
        if (currentTutorData.schedule.length !== 0) {
          //console.log("wasnt empty" ,(currentTutorData.schedule).concat(newSchedule))
          //Merge timeslots
          //tempTutor.schedule = dateObjectSchedule.concat(newSchedule)
          tempTutor.schedule = insertSorted(newSchedule)
          setDateObjectSchedule(tempTutor.schedule)
          setCurrentTutorData(tempTutor)
          tempTutor.schedule = JSON.stringify(tempTutor.schedule)
        } else{
          setCurrentTutorData(tempTutor)
          tempTutor.schedule = JSON.stringify(newSchedule)
        }
        
        if (typeof tempTutor.subjects !== 'string') {
          tempTutor.subjects = JSON.stringify(tempTutor.subjects);
      }
        console.log("tempTutor before update," ,tempTutor)

        mutate({
          resource: "tutors",
          values: { tempTutor
          },
          id: currentTutorData?._id
        });
    }

    const handleRemoveClick = () => {

      const newSchedule = generateSchedule(removeDaysOfWeek, removeStartTime, removeEndTime, removeStartDate, removeEndDate)
        const tempTutor = currentTutorData;
        if (currentTutorData.schedule.length !== 0) {
          
          tempTutor.schedule = removeSorted(newSchedule)
          setDateObjectSchedule(tempTutor.schedule)
          tempTutor.schedule = JSON.stringify(tempTutor.schedule)
        } else{
          tempTutor.schedule = JSON.stringify(newSchedule)
        }
        
        if (typeof tempTutor.subjects !== 'string') {
          tempTutor.subjects = JSON.stringify(tempTutor.subjects);
      }
      
        mutate({
          resource: "tutors",
          values: { tempTutor
          },
          id: currentTutorData?._id
        });

    }

    function generateSchedule(
        daysOfWeek: boolean[],   // An array of booleans representing days of the week (e.g., [Sun, Mon, Tue, Wed, Thu, Fri, Sat])
        startTime: string,      // Start time in HH:mm format (e.g., '09:00')
        endTime: string,        // End time in HH:mm format (e.g., '17:00')
        startDate: string,      // Start date in 'YYYY-MM-DD' format (e.g., '2023-10-16')
        endDate: string         // End date in 'YYYY-MM-DD' format (e.g., '2023-10-31')
      ): TimeSlot[] {            // The function returns an array of Schedule objects
      
        const result: TimeSlot[] = [];  // Initialize an empty array to store the generated schedule
        const start = new Date(startDate);  // Create a Date object from the provided start date
        const end = new Date(endDate);      // Create a Date object from the provided end date
      
        // Ensure the start and end dates are in the same time zone (UTC)
        //start.setUTCHours(0, 0, 0, 0);
        //end.setUTCHours(0, 0, 0, 0);
      
        while (start <= end) {  // Loop through the date range from start to end
          const dayIndex = start.getDay();  // Get the day of the week (0 = Sunday, 1 = Monday, ...)
      
          // Check if the day of the week is selected based on the daysOfWeek array
          if (daysOfWeek[dayIndex]) {
            const startTimeParts = startTime.split(':');
            const endTimeParts = endTime.split(':');
      
            const startDateTime = new Date(start);
            startDateTime.setHours(parseInt(startTimeParts[0], 10));  // Set hours (UTC)
            startDateTime.setMinutes(parseInt(startTimeParts[1], 10));  // Set minutes (UTC)
      
            const endDateTime = new Date(start);
            endDateTime.setHours(parseInt(endTimeParts[0], 10));  // Set hours (UTC)
            endDateTime.setMinutes(parseInt(endTimeParts[1], 10));  // Set minutes (UTC)
      
            // Push an object with startTime and endTime to the result array
            result.push({
              startTime: startDateTime,
              endTime: endDateTime,
            });
          }
      
          // Move to the next day by incrementing the date (UTC)
          start.setDate(start.getDate() + 1);
        }

        console.log("result", result)
        //console.log("transform", )        
        return result;  // Return the generated schedule
      }
    
      const removeSorted = (newObjects : any) => {
        newObjects.forEach((newObject : any) => {
          const newStartTime = newObject.startTime;
          const newEndTime = newObject.endTime;
          
          const dateLimit = new Date(newStartTime.getUTCFullYear(), newStartTime.getUTCMonth(), newStartTime.getUTCDate(), 0, 0, 0, 0);

          let index = 0;
          
          while (index<dateObjectSchedule.length && dateLimit>dateObjectSchedule[index].startTime)
            {index++;}

          dateLimit.setHours(23)
          dateLimit.setMinutes(59)
          dateLimit.setSeconds(59)
          dateLimit.setMilliseconds(999)

          while (index<dateObjectSchedule.length && dateObjectSchedule[index].startTime< dateLimit)
          {
            const currStartTime = dateObjectSchedule[index].startTime;
            const currEndTime = dateObjectSchedule[index].endTime;

            if(newStartTime<currStartTime)
            {
              if(newEndTime<=currStartTime)
              {
                //timeslot does not intersect at all
                break;
              }
              else
              {
                if(newEndTime<currEndTime)
                {
                  //timeslot intersects beginning
                  dateObjectSchedule[index].startTime = newEndTime;
                  break;
                }
                else
                {
                  //remove entire slot and continue to next timeSlot
                  dateObjectSchedule.splice(index,1);
                }
              }
            }
            else
            {
              if(newStartTime>=currEndTime)
              {
                //continue to next timeSlot
              }
              else
              {
                if(newEndTime<currEndTime)
                {
                  //split into two slots
                  const secondSlot = {startTime: newEndTime, endTime: currEndTime};
                  dateObjectSchedule[index].endTime = newStartTime;

                  dateObjectSchedule.splice(index, 0, secondSlot);
                }
                else
                {
                  //intersects with end and continue to next timeSlot
                  dateObjectSchedule[index].endTime = newStartTime;
                }
              }
            }

          }
        }); 
    
        // Update the state with the modified data array after each insertion
        //setDateObjectSchedule([...dateObjectSchedule]);
        return dateObjectSchedule;
      };

      const insertSorted = (newObjects : any) => {
        newObjects.forEach((newObject : any) => {
          const newStartTime = newObject.startTime;
          const newEndTime = newObject.endTime;
          
          const dateLimit = new Date(newStartTime.getUTCFullYear(), newStartTime.getUTCMonth(), newStartTime.getUTCDate(), 0, 0, 0, 0);

          let index = 0;
          let shouldInsert = true;
          let shouldAdd = true;
          
          while (index<dateObjectSchedule.length && dateLimit>dateObjectSchedule[index].startTime)
            {index++;}

          dateLimit.setHours(23)
          dateLimit.setMinutes(59)
          dateLimit.setSeconds(59)
          dateLimit.setMilliseconds(999)

          while (index<dateObjectSchedule.length && dateObjectSchedule[index].startTime< dateLimit)
          {
            const currStartTime = dateObjectSchedule[index].startTime;
            const currEndTime = dateObjectSchedule[index].endTime;

            var mergeStartTime;
            var mergeEndTime;

            if (newStartTime<currStartTime)
            {
              if (newEndTime<currStartTime)
              {
                //normal insert
                dateObjectSchedule.splice(index, 0, newObject);
                shouldAdd=false; 
                break;
              }
              else if (newEndTime<=currEndTime)
              {
                //merge to start
                dateObjectSchedule[index].startTime = newStartTime;
                shouldAdd=false;
              }
              else
              {
                //newTimeSlot overlaps entire currTimeSlot
                //remove the currTimeSlot
                //check newTimeSlot against nextTimeSlot
                shouldAdd=true;
                dateObjectSchedule.splice(index,1);
              }
            }

            else if (newStartTime>currEndTime)
            {
              //newTimeSlot is completely after currTimeSlot  
              shouldAdd=true;
            }
            else if (newEndTime<=currEndTime)
            {
              //newTimeSlot is completely inside currTimeSlot
              shouldAdd=false;
              break;
            }
            else
            {
              //merge to end
              //newStartTime = currStartTime;
              mergeStartTime = currStartTime // solved the changing a const variable error. however this is potentially not needed?

              dateObjectSchedule.splice(index,1);
              shouldAdd=true;
            }
          }
          if (shouldAdd)
          {
            newObject.startTime = newStartTime; //in case of end merge
            dateObjectSchedule.splice(index, 0, newObject);
          }
        }); 
    
        // Update the state with the modified data array after each insertion
        //setDateObjectSchedule([...dateObjectSchedule]);
        return dateObjectSchedule;
      };
    
      //Function that coverts ISO 8601 date strings into Date objects in array of objects
      function parseDatesInObjects(data: TimeSlot): TimeSlot[] {
        return data.map((obj: { startTime: string; endTime: string; }) => ({
          ...obj,
          startTime: new Date(obj.startTime),
          endTime: new Date(obj.endTime),
        }));
      }

      function modifiedTimezone(schedule : any) {
        const timezoneOffset = "-04:00"

        const modifiedSchedule = schedule.map((timeSlot: { startTime: any, endTime: any }) => {
          timeSlot.startTime = timeSlot.startTime.replace("Z", timezoneOffset)
          timeSlot.endTime = timeSlot.endTime.replace("Z", timezoneOffset)
          return timeSlot;
        })

        return modifiedSchedule;
      }

      return (
        <div className="p-4 max-w-xl mx-auto">
          <div
            onClick={toggleCustomComponent}
            className="cursor-pointer text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 underline text-blue-500 hover:text-blue-700"
          >
            What your schedule looks like
          </div>
      
          {showCustomComponent && (
            <div>
              {/* Your custom component content goes here */}
              <ScheduleMeeting
                borderRadius={10}
                primaryColor="#3f5b85"
                eventDurationInMinutes={30}
                availableTimeslots={currentTutorData.schedule}
                //onStartTimeSelect={handleStartTimeSelect}
                eventStartTimeSpreadInMinutes={0}
              />
            </div>
          )}
          <br />
      
          <Availability
            type="Add"
            startTimeFieldValue={addStartTime}
            endTimeFieldValue={addEndTime}
            startDateFieldValue={addStartDate}
            endDateFieldValue={addEndDate}
            setStartTimeFieldValue={setAddStartTime}
            setEndTimeFieldValue={setAddEndTime}
            setStartDateFieldValue={setAddStartDate}
            setEndDateFieldValue={setAddEndDate}
            daysOfWeek={addDaysOfWeek}
            setDaysOfWeek={setAddDaysOfWeek}
            handleClick={handleAddClick}
          />
          {/*
          <input
            type="datetime-local"
            className="border border-gray-300 rounded-md p-2 mt-2"
            required
            value={startDates}
            onChange={handleStartDateChange}
          /> */}
          <br />
      
          <Availability
            type="Remove"
            startTimeFieldValue={removeStartTime}
            endTimeFieldValue={removeEndTime}
            startDateFieldValue={removeStartDate}
            endDateFieldValue={removeEndDate}
            setStartTimeFieldValue={setRemoveStartTime}
            setEndTimeFieldValue={setRemoveEndTime}
            setStartDateFieldValue={setRemoveStartDate}
            setEndDateFieldValue={setRemoveEndDate}
            daysOfWeek={removeDaysOfWeek}
            setDaysOfWeek={setRemoveDaysOfWeek}
            handleClick={handleRemoveClick}
          />
        </div>
      );
      
}

export default SetSchedule
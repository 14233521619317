import { useAppContext } from "contexts/AppContext";
import { CredentialResponse } from "interfaces/google";
import React, { useState, useContext, useEffect, useRef} from "react";
import { useRouterContext, useLogin, useLogout, useTranslate } from "@refinedev/core";

function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { mutate: login } = useLogin<CredentialResponse>({
    v3LegacyAuthProviderCompatible: true
  });
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();

  const GoogleButton: React.FC<{}> = ({}) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }
      
      try {
        window.google.accounts.id.initialize({
          ux_mode: "popup",
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
          callback: async (res: CredentialResponse) => {
            if (res.credential) {
              login(res);
              setLoggedIn(true);
            }
          },
        });
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []); // you can also add your client id as dependency here

    return <div ref={divRef} />;
  };


  const handleSignIn = () => {
    // Add your sign-in logic here
  };

  const handleGoogleSignIn = () => {
    
  };

  return (
    <div className="p-4 bg-gray-200 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Sign In</h2>
        
        {/* Username Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        {/* Password Input */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        {/* Sign In Button */}
        <button
          onClick={handleSignIn}
          className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
        >
          Sign In
        </button>

        {/* Or Separator */}
        <div className="mt-4 flex items-center justify-center">
          <div className="border-t border-gray-400 flex-grow"></div>
          <span className="mx-2 text-gray-500">Or</span>
          <div className="border-t border-gray-400 flex-grow"></div>
        </div>

        {/* Google Sign-In Button */}
        <GoogleButton ></GoogleButton>
      </div>
    </div>
  );
}

export default SignIn;

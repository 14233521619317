import React, { useContext, useEffect, useRef} from "react";
import { useRouterContext, useLogin, useLogout, useTranslate } from "@refinedev/core";
import { ColorModeContext } from "contexts";
import { CredentialResponse } from "../../../interfaces/google";
import { useAppContext } from "contexts/AppContext";

import { Button } from "../../ui/button"
import NavItems from "./navItems"
import MobileNav from "./mobileNav"

export const Header: React.FC = () => {
  const { mutate: login } = useLogin<CredentialResponse>({
    v3LegacyAuthProviderCompatible: true
  });
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();
  const t = useTranslate();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: true
  });

  const GoogleButton = (): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }
      
      try {
        window.google.accounts.id.initialize({
          ux_mode: "popup",
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
          callback: async (res: CredentialResponse) => {
            if (res.credential) {
              login(res);
              setLoggedIn(true);
            }
          },
        });
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []); // you can also add your client id as dependency here

    return <div ref={divRef} />;
  };

  const { mode, setMode } = useContext(ColorModeContext);

  const { Link } = useRouterContext();
  //const { data: user } = useGetIdentity();

  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.
  return shouldRenderHeader ? ( 
  <header className="w-full border-b" style={{ backgroundColor: '#090e2b' }}>
    <div className="wrapper flex items-center justify-between">
      <a href="/" className="w-36">
        <img 
          src="/assets/images/sc.png" width={128} height={38}
          alt="Evently logo" 
        />
      </a>
        <nav className="ml-20 hidden md:flex-between w-full justify-center md:flex">
          <NavItems />
        </nav>
    
      <div className="flex w-32 justify-end gap-3">
          <MobileNav /> 
      </div>
    </div>
</header> ) : null
};

import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import {Profile } from 'components';
import SelectSubject from "components/tutor/SelectSubject";

const TutorProfile = () => {
  const { id } = useParams();

  const { data, isLoading, isError} = useOne({
    resource:'tutors',
    id: id as string
  })

  console.log("data is ", data);
  
  const tutorToBeBooked: any = {
    ...data?.data,
    subjects: data?.data?.subjects ? JSON.parse(data.data.subjects) : [],
    schedule: data?.data?.schedule ? JSON.parse(data.data.schedule) : [],
  };
  console.log(tutorToBeBooked);

  if(isLoading) return <div>Loading...</div>
  if(isError) return <div>Error...</div>

  return (
      <Profile 
      type="Tutor"
      firstName={tutorToBeBooked.User.firstName}
      lastName={tutorToBeBooked.User.lastName}
      email={tutorToBeBooked.User.email}
      avatar={tutorToBeBooked.User.avatar}
      subjects={tutorToBeBooked.subjects}
      description={tutorToBeBooked.tutorDescription}
      rating={tutorToBeBooked.rating}
      isTutor={true}
      tutorToBeBooked={tutorToBeBooked}
      />
  )
}

export default TutorProfile
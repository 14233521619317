import React, { createContext, useContext, useState } from 'react';
import { TutorProfileProps, StudentProfileProps, UserProfileProps } from 'interfaces/common';

interface AppContextProps {
  currentStudentData: any; // Replace StudentData with the actual type of student data
  currentTutorData: any; // Replace TutorData with the actual type of tutor data
  currentUserData: any;
  loggedIn : boolean;
  isMobile : boolean;
  setCurrentStudentData: (data: StudentProfileProps | null) => void;
  setCurrentTutorData: (data: TutorProfileProps | null) => void;
  setCurrentUserData: (data: UserProfileProps | null) => void;
  setLoggedIn : (data: boolean ) => void;
  setIsMobile: (data :boolean) => void;
}

const AppContext = createContext<AppContextProps>({
  currentStudentData: null,
  currentTutorData: null,
  currentUserData: null,
  loggedIn: false,
  isMobile: false,
  setCurrentStudentData: (data : any) => {}, // Accepts an argument now
  setCurrentTutorData: (data : any) => {}, // Accepts an argument now
  setCurrentUserData: (data : any) => {}, // Accepts an argument now
  setLoggedIn: (data : boolean) => {},
  setIsMobile: (data : boolean) => {}
});

export const AppProvider = ({ children } : any) => {
  const [state, setState] = useState({
    currentStudentData: null,
    currentTutorData: null,
    currentUserData: null,
    loggedIn: false,
    isMobile: false,
  });

  const setCurrentStudentData = (data : any) => {
    setState((prevState) => ({
      ...prevState,
      currentStudentData: data,
    }));
  };

  const setCurrentTutorData = (data : any) => {
    setState((prevState) => ({
      ...prevState,
      currentTutorData: data,
    }));
  };

  const setCurrentUserData = (data : any) => {
    setState((prevState) => ({
      ...prevState,
      currentUserData: data,
    }));
  };

  const setLoggedIn = (data : boolean) => {
    setState((prevState) => ({
      ...prevState,
      loggedIn: data,
    }));
  }

  const setIsMobile = (data : boolean) => {
    setState((prevState) => ({
      ...prevState,
      isMobile: data,
    }));
  }

  return (
    <AppContext.Provider value={{ ...state, setCurrentStudentData, setCurrentTutorData, setCurrentUserData, setLoggedIn, setIsMobile }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

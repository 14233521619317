import { useNavigate } from "react-router-dom";
import { Button } from 'components/ui/button';
import { Subject, SubjectSelectProps } from 'interfaces/tutor';
import React, { useState } from 'react';

const SelectSubject = ({ tutor }: SubjectSelectProps) => {
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState<Subject>({ name: '', price: '' });

  const handleClick = (t: any, isImmediate: boolean) => {
    const state = { tutorToBeBooked: t, isImmediate, selectedSubject };
    navigate(`/booking/create/${t._id}`, { state });
  };

  const handleSubjectSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedSubject = event.target.value;
    const foundSubject = tutor.subjects.find((subject) => subject.name === newSelectedSubject);
    if (foundSubject) {
      setSelectedSubject(foundSubject);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
    <label className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold mb-2 flex flex-col items-center"> {/* Adjust the label structure */}
      Select a Subject
      <div className="w-full max-w-md"> {/* Add this container with max width */}
        <select
          value={selectedSubject.name}
          onChange={handleSubjectSelect}
          className="mt-2 w-full border border-gray-300 rounded-md px-2 text-lg md:text-xl lg:text-2xl mx-auto"
        >
          <option value="" style={{ fontSize: '1rem', height: '1.5rem' }}></option>
          {tutor.subjects.map((subject: Subject) => (
            <option key={subject.name} value={subject.name} style={{ fontSize: '1rem' }}>
              {subject.name}
            </option>
          ))}
        </select>
        <p className="mt-2 md:ml-4 text-lg">Hourly rate $ {selectedSubject.price}</p> {/* Move the text here */}
      </div>
    </label>
      <div className="flex flex-col gap-2">
        <Button
          variant="default"
          size="default"
          className="bg-primary text-primary-foreground hover:bg-primary/90"
          onClick={() => handleClick(tutor, false)}
          disabled={false}
        >
          Schedule Tutor
        </Button>
        <Button
          variant="default"
          size="default"
          className={tutor.isActive ? 'bg-primary text-primary-foreground hover:bg-primary/90' : 'bg-ccc text-666'}
          onClick={() => tutor.isActive && handleClick(tutor, true)}
          disabled={!tutor.isActive}
        >
          Instant Booking
        </Button>
      </div>
    </div>
  );
};

export default SelectSubject;

import React from 'react'

const OurApproach = () => {
  return (
    <div className='container mx-auto px-4 max-w-screen-xl'>
    <main >
      <h1 style={{color:'black'}}>
      SMART Goals
      </h1>
      <h2 style={{color:'black', textAlign:'center' }}>
      Goal setting is an important factor in effective tutoring sessions
      </h2>
      <h3>
        <p style={{color:'black', fontWeight:'lighter'}}>- Every tutor will set their student's development objectives, keeping in mind the tools and resources that will be needed to achieve them.</p>
        <p style={{color:'black', fontWeight:'lighter'}}>- Whatever development goals may be for the students, our experienced instructors at STEM UK can help and assist the students to achieve their goals with best results  by choosing the resources that work right for the students.</p>
        <p style={{color:'black', fontWeight:'lighter'}}>- Tutors may collaborate with other students, do online research, invite commentary from students and parents, or help through workshops. There is a way to position students for success by making SMART Goals.</p>
      </h3>
      <table style={{marginLeft:'auto', marginRight:'auto'}}>
      <tr>
        <td style= {{verticalAlign: 'middle', backgroundColor: '#ff4040', borderRadius: '25px', paddingLeft: '15px', color:'black', fontWeight:'bold'}}>
            S-Specific
        </td>
        <td style={{backgroundColor: '#ff8d8d', borderRadius: '25px', paddingLeft: '20px', paddingRight: '20px', fontWeight:'normal', color:'black'}}>
          - WHAT  DO  YOU  WANT  YOUR  CHILD  TO  DO?<br></br>
          - SET  SPECIFIC  MILESTONES  FOR  YOUR  CHILD'S  DEVELOPMENT  GOALS
        </td>
      </tr>
      <tr>
        <td style={{verticalAlign: 'middle', backgroundColor: '#fc8814', borderRadius: '25px', paddingLeft: '15px', color:'black', fontWeight:'bold' }}>
          M- Measurable
        </td>
        <td style={{backgroundColor: '#ffc387', borderRadius: '25px', paddingLeft: '20px',paddingRight: '20px', fontWeight:'normal', color:'black'}}>
          - HOW  WILL  YOU  KNOW  WHEN  YOU  HAVE  REACHED  THE  GOALS?<br></br>
          - SET  TARGETS  FOR  EACH  CHILD'S  DEVELOPMENTAL  MILESTONES
        </td>
      </tr>
      <tr>
        <td style= {{verticalAlign: 'middle', backgroundColor: '#1cc704', borderRadius: '25px', paddingLeft: '15px', color:'black', fontWeight:'bold' }}>
          A- Achievable
        </td>
        <td style={{backgroundColor: '#aefea3', borderRadius: '25px', paddingLeft: '20px',paddingRight: '20px', fontWeight:'normal', color:'black'}}>
          - CAN  CHILD  ACHIEVE  THE  GOALS  THAT  ARE  SET?<br></br>
          - WHAT  RESOURCES  AND  TECHNIQUES  CAN  HELP  TO  ACHIEVE  SUCCESS?
        </td>
      </tr>
      <tr>
        <td style= {{verticalAlign: 'middle', backgroundColor: '#2c7bff', borderRadius: '25px', paddingLeft: '15px', color:'black', fontWeight:'bold'}}>
          R- Realistic
        </td>
        <td style={{backgroundColor: '#9cc1ff', borderRadius: '25px', paddingLeft: '20px',paddingRight: '20px', fontWeight:'normal', color:'black'}}>
          - IS  THE  GOAL  REALISTIC  TO  ACHIEVE  SUCCESS?<br></br>
          - IS  THE  CRITERION  REALISTIC  TO  DEFINE  SUCCESS?
        </td>
      </tr>
      <tr>
        <td style= {{verticalAlign: 'middle', backgroundColor: '#bc3bff', borderRadius: '25px', paddingLeft: '15px', color:'black', fontWeight:'bold'}}>
          T- Time Bound
        </td>
        <td style={{backgroundColor: '#dd9bff', borderRadius: '25px', paddingLeft: '20px', paddingRight: '20px', fontWeight:'normal', color:'black'}}>
          - WHEN  EXACTLY  CAN  CHILD  ACHIEVE  THE  GOALS?<br></br>
          - CAN  CHILD  WAIT  FOR  EXTENDED  PERIOD  TO  ACHIEVE  GOALS?
        </td>
      </tr>
      </table>
      {/* <h1 style={{color:'black'}}>
      Our Method
      </h1> */}
    </main>
    </div>
    
  )
}

export default OurApproach
import { useUpdate } from "@refinedev/core";
import { useState, useEffect, ChangeEvent } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { Profile, TutorSettings, Sessions } from 'components';
import SetSchedule from 'components/tutor/SetSchedule';

const TutorDashboard = () => {
  // States brought in
  const { currentTutorData } = useAppContext();

  const { mutate } = useUpdate();

  // States changing on page
  const [isActive, setIsActive] = useState<boolean>(currentTutorData.isActive);

  const [showTutorProfile, setTutorProfile] = useState(true);
  const [showSetSchedule, setShowSetSchedule] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  // Use a useEffect to log the updated schedule
  useEffect(() => {
    // console.log(isActive);
  }, [isActive]);

  function handleStatusToggle(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    setIsActive(checked);
    const tempTutor = currentTutorData;
    tempTutor.isActive = checked;
    console.log('updating', tempTutor);
    tempTutor.schedule = JSON.stringify(tempTutor.schedule)
    tempTutor.subjects = JSON.stringify(tempTutor.subject)
    mutate({
      resource: 'tutors',
      values: {
        tempTutor,
      },
      id: currentTutorData?._id,
    });
  }

  function TutorProfile() {
    return (
      <Profile
        type="My Tutor"
        firstName={currentTutorData.User.firstName}
        lastName={currentTutorData.User.lastName}
        email={currentTutorData.User.email}
        avatar={currentTutorData.User.avatar}
        description={currentTutorData.tutorDescription}
        subjects={currentTutorData.subjects}
        handleToggle={handleStatusToggle}
        isActive={isActive}
        isTutor={true}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 max-w-screen-xl">
      <nav className="bg-blue-900 p-4 flex items-center">
        <div className="flex justify-between w-full">
          <div className="flex space-x-4 items-center mx-auto">
            <button
              onClick={() => {
                setTutorProfile(true);
                setShowSetSchedule(false);
                setShowSessions(false);
                setShowSettings(false);
              }}
              className={`${
                showTutorProfile ? 'text-black text-sm' : 'text-white text-sm'
              } md:text-base px-2 py-1`}
            >
              My Profile
            </button>
            <button
              onClick={() => {
                setShowSetSchedule(true);
                setTutorProfile(false);
                setShowSessions(false);
                setShowSettings(false);
              }}
              className={`${
                showSetSchedule ? 'text-black text-sm' : 'text-white text-sm'
              } md:text-base px-2 py-1`}
            >
              My Schedule
            </button>
            <button
              onClick={() => {
                setShowSessions(true);
                setTutorProfile(false);
                setShowSetSchedule(false);
                setShowSettings(false);
              }}
              className={`${
                showSessions ? 'text-black text-sm' : 'text-white text-sm'
              } md:text-base px-2 py-1`}
            >
              Sessions
            </button>
            <button
              onClick={() => {
                setShowSettings(true);
                setTutorProfile(false);
                setShowSetSchedule(false);
                setShowSessions(false);
              }}
              className={`${
                showSettings ? 'text-black text-sm' : 'text-white text-sm'
              } md:text-base px-2 py-1`}
            >
              Settings
            </button>
          </div>
        </div>
      </nav>
      <br />

      {showTutorProfile && <TutorProfile />}
      {showSetSchedule && <SetSchedule />}
      {showSessions && <Sessions />}
      {showSettings && <TutorSettings />}
    </div>
  );
};

export default TutorDashboard;


import { Button } from '../ui/button'

function Availability({type, startTimeFieldValue, endTimeFieldValue, startDateFieldValue, endDateFieldValue, setStartTimeFieldValue, setEndTimeFieldValue, setStartDateFieldValue, setEndDateFieldValue, daysOfWeek, setDaysOfWeek, handleClick } : any ) {
    //Constants 
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    const handleDayOfWeekChange = (index : any) => {
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[index] = !updatedDaysOfWeek[index];
        setDaysOfWeek(updatedDaysOfWeek);
        console.log(updatedDaysOfWeek)
  };
  
    //Functions for Schedule Input Change
    const handleStartFieldChange = (event: any) => {setStartTimeFieldValue(event.target.value)}
    const handleEndFieldChange = (event: any) => {setEndTimeFieldValue(event.target.value)}
    const handleStartDateFieldChange = (event: any) => {setStartDateFieldValue(event.target.value)}
    const handleEndDateFieldChange = (event: any) => {setEndDateFieldValue(event.target.value)}

    return (
      <div className="p-4">
        <div className="mb-4">
          <h1 className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
            {type} Availability:
          </h1>
        </div>
    
        <div className="flex gap-2">
          <input
            type="time"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={startTimeFieldValue}
            onChange={handleStartFieldChange}
          />
    
          <input
            type="time"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={endTimeFieldValue}
            onChange={handleEndFieldChange}
          />
        </div>
    
        <div className="flex gap-2">
          <input
            type="date"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={startDateFieldValue}
            onChange={handleStartDateFieldChange}
          />
    
          <input
            type="date"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={endDateFieldValue}
            onChange={handleEndDateFieldChange}
          />
        </div>
    
        <div>
          <h2 className="text-xl font-bold mb-2">Select Days of the Week:</h2>
          <div className="flex gap-2">
            {dayNames.map((day, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="checkbox"
                  checked={daysOfWeek[index]}
                  onChange={() => handleDayOfWeekChange(index)}
                  className="h-5 w-5 md:h-5 md:w-5 lg:h-6 lg:w-6 xl:h-7 xl:w-7"
                />
                <label className="ml-2 text-sm">{day.substring(0, 2)}</label>
              </div>
            ))}
          </div>
    
          <Button
          color="#fcfcfc"
          onClick={handleClick}
          className="mt-5 px-6 py-3 text-sm md:text-base lg:text-lg xl:text-xl h-12 w-36 md:w-48 lg:w-60 xl:w-72"
          >
            {type} Availability
          </Button>

        </div>
      </div>
    );
    
    
}

export default Availability
import React from "react";
import { Box, AppBar, Toolbar, Typography } from "@mui/material";

export const Footer: React.FC = () => {
  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.

  return shouldRenderHeader ? (
    <AppBar position="sticky" elevation={1} sx={{ backgroundColor: "#4c5aa3" }}>
      <Toolbar>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          textAlign="center"
        >
          <Typography variant="body1" fontWeight="bold" color="white">
            STEMConnects is part of STEM Academy For Young Kids and iGo STEM Academy
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  ) : null;
};

import React, { useState, useEffect } from "react";
import { Refine, LegacyAuthProvider as AuthProvider } from "@refinedev/core";
import { notificationProvider, RefineSnackbarProvider, ReadyPage, ErrorComponent } from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import dataProvider from "@refinedev/simple-rest";
import routerProvider from "@refinedev/react-router-v6/legacy";
import axios, { InternalAxiosRequestConfig } from 'axios';
import { ColorModeContextProvider } from "contexts";
import { Title, Layout, Header, Footer } from "components/layout";
import { CredentialResponse } from "interfaces/google";
import { parseJwt } from "utils/parse-jwt";
import { TutorDashboard, BookATutor, Faq, OurApproach, TutorApplication, MyProfile, TutorProfile, Booking, Homepage, SignIn } from "pages";
import Checkout from "components/common/Checkout";
import { Success, Error } from "components";
//import {} from "contexts/product.context";
import {
  AccountCircleOutlined,
  Person,
  LiveHelp,
  HistoryEdu,
  Info,
} from '@mui/icons-material'
import { useAppContext } from './contexts/AppContext';
import StudentDashboard from "pages/student-dashboard";
import 'css/tailwind.css'


const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    } as any;  // TypeScript workaround
  }
  return request;
});
function App() {
  console.log("first");
  let user : any | null = null;
  const { currentStudentData, currentTutorData, currentUserData, loggedIn, isMobile, setCurrentStudentData, setCurrentTutorData, setCurrentUserData, setLoggedIn, setIsMobile} = useAppContext();
  const [isVisitor, setIsVisitor] = useState(true)
  const [isTutor, setIsTutor] = useState(Boolean);

  /*const mobileCheck = () => {
      if(window.innerWidth <= 960) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }*/
  
  const localStorage_user = localStorage.getItem('user');
  if (localStorage_user !== null) {
    user = JSON.parse(localStorage_user.toString());
    //getStudentData(user.userid)
  } else {
    // Handle the case where localStorage_user is not set
  }
  
  //window.addEventListener('resize', mobileCheck)
  
  useEffect(() => {
    console.log("insidde effect STUDENT", currentStudentData)
    console.log("insidde effect Tutor", currentTutorData)
    const fetchData = async () => {
      if (user != null && !loggedIn && !currentTutorData && !currentStudentData) {
        const userId = user.userid;
        try {
          await checkIfStudentOrTutor(userId);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    fetchData();
  }, [currentTutorData, currentStudentData]);
  

  const DashboardPage = loggedIn ? (isTutor ? TutorDashboard : StudentDashboard) : Homepage;

  //getStudentData(user.userid)
  
  //Code doesnt seem to reach here on login, but does on refresh
  // Use the user object here
  console.log("running in app.tsx, user is " , user);

  const authProvider: AuthProvider = {   
    login: async ({ credential }: CredentialResponse) => {
      const profileObj = credential ? parseJwt(credential) : null;
      if (profileObj) {
        //Return User if already 
        const createuser = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/users`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: profileObj.name,
            firstName: profileObj.name.substring(0,profileObj.name.lastIndexOf(" ")),
            lastName: profileObj.name.substring(profileObj.name.lastIndexOf(" ")+1,profileObj.name.length),                 
            email: profileObj.email,
            avatar: profileObj.picture
          })
        })

        const user = await createuser.json();
      
        if(createuser.status === 200 || createuser.status === 201)
          {
            localStorage.setItem(
            "user",
            JSON.stringify({
              ...profileObj,
              avatar: profileObj.picture,
              userid: user._id
            })
          );
        }   
        else{
          return Promise.reject()
        }

        await checkIfStudentOrTutor(user._id);
      }
      
      localStorage.setItem("token", `${credential}`);

      return Promise.resolve();
    },
    logout: () => {
      const token = localStorage.getItem("token");

      if (token && typeof window !== "undefined") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axios.defaults.headers.common = {};
        window.google?.accounts.id.revoke(token, () => {
          return Promise.resolve("/home");
        });
      }

      setLoggedIn(false);
      setCurrentStudentData(null)
      setCurrentTutorData(null)
      
      return Promise.resolve("/home");
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = localStorage.getItem("token");

      /*
      if (token) {
        return Promise.resolve();
      }
      return Promise.reject();*/
      // Always resolve the promise, even if the user is not authenticated
      return Promise.resolve();
    },

    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
        return Promise.resolve(JSON.parse(user));
      }
    },
  };

  /*Function has two purposes
    1) Create new student upon signup
    2) Get status of user : Student or Tutor
  */
  async function checkIfTutor(userId : any) {
    const tutorCheck = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/tutors/${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const tutor = await tutorCheck.json();

    if (tutorCheck.status === 200){
      setIsTutor(true)
      
      const parsedTutorData: any = {
        ...tutor,
        subjects: tutor.subjects ? JSON.parse(tutor.subjects) : [],
        schedule: tutor.schedule ? JSON.parse(tutor.schedule) : [],
      };
      setCurrentTutorData(parsedTutorData)
      return true;
    } 
    else if (tutorCheck.status === 404){
      setIsTutor(false)
      return false
    }
  }

  async function getStudentData(userId: any) {
    try {
      const createStudentRequest = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/students`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userId
        })
      });
  
      const student = await createStudentRequest.json();
      setCurrentStudentData(student);
      return true;
    } catch (error) {
      console.error("Error fetching student data:", error);
      return false;
    }
  }

  async function checkIfStudentOrTutor(userId : any) {
    
    const userCheck = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/users/${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const user = await userCheck.json();
    setCurrentUserData(user);
    setLoggedIn(true)

    if (await checkIfTutor(userId)) {
      console.log("user is a tutor")
    }
    else if(await getStudentData(userId)){
      console.log("user is a student")
    }
  }

  return <>
    <CssBaseline />
    <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
    <RefineSnackbarProvider>
      <Refine
        dataProvider={dataProvider(`${process.env.REACT_APP_SERVER}/api/v1`)}
        notificationProvider={notificationProvider}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        resources={  [
          {
            name: "sign-in",
            list: SignIn,
          },

          {
            name: "book-a-tutor",
            meta: {label: 'Book A Tutor', hide: true },
            list: BookATutor,
            show: TutorProfile,
            icon: <Person />,
          },
          {
            name: "faq",
            meta: {label: 'FAQ' },
            list: Faq,
            icon: <LiveHelp />,
          },
          {
            name: "our-approach",
            list: OurApproach,
            icon: <Info />,
          },
          {
            name: "tutor-application",
            list: TutorApplication,
            icon: <HistoryEdu />,
            meta: {hide: true }
          },
          {
            name: "my-profile",
            meta: {label: 'My Profile', hide: true },
            list: MyProfile,
            icon: <AccountCircleOutlined />
          },
          {
            name: "booking",
            create: Booking,
            meta: {hide: true },
          },        
          { 
            name: "home", 
            list: Homepage 
          },       
          {
            name: "checkout",
            list: Checkout,
            meta: {hide: true },
          },
          {
            name: "success",
            list: Success,
            meta: {hide: true },
          },{
            name: "error",
            list: Error,
            meta: {hide: true },
          },
        ]}
        Title={Title}
        //Sider={Sider}
        Layout={Layout}
        Header={Header}
        Footer={Footer} 
        legacyRouterProvider={routerProvider}
        legacyAuthProvider={authProvider}
        //LoginPage={Login}
        DashboardPage={DashboardPage}//change dashboard if user is a tutor
      >
      </Refine>
      
    </RefineSnackbarProvider>
  </>;
}

export default App;

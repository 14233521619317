import React, { useState } from 'react';
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { makeValidJSON } from 'functions/common';
import { useAppContext } from 'contexts/AppContext';
import { useUpdate } from "@refinedev/core";

import { Button } from '../ui/button'

const TutorSettings = () => {
    const { currentTutorData, currentUserData, setCurrentTutorData, setCurrentUserData } = useAppContext();
    const [subjects, setSubjects] = useState(currentTutorData.subjects);
    const [newSubject, setNewSubject] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newFirstName, setNewFirstName] = useState(currentUserData.firstName);
    const [newLastName, setNewLastName] = useState(currentUserData.lastName);
    const [newTutorDescription, setNewTutorDescription] = useState(currentTutorData.tutorDescription);
    const { mutate } = useUpdate();
    
  console.log("USER DATA: ", currentUserData)

    const handleInputEdit = (index : number, newPrice: number) => {
        const updatedSubjects  = [...subjects];
        updatedSubjects[index].price = newPrice;
        setSubjects(updatedSubjects);
      };
  
      const onEditClick = () => {
        setIsEditing(true);
      };
  
      const handleDoneClick = () => {
        const tempTutor = currentTutorData;
        tempTutor.subjects = JSON.stringify(subjects);
        tempTutor.tutorDescription = newTutorDescription;

        const tempUser = currentUserData;
        tempUser.firstName = newFirstName;
        tempUser.lastName = newLastName;
        console.log("updating",);
        tempTutor.schedule = JSON.stringify(tempTutor.schedule)
        mutate({
          resource: "tutors",
          values: { tempTutor
          },
          id: currentTutorData?._id
      }, {
        onSuccess: (data, variables, context) => {
          setCurrentTutorData(tempTutor)
        }
      });

      mutate({
        resource: "users",
        values: { tempUser
        },
        id: currentUserData?._id

    }, {
      onSuccess: (data, variables, context) => {
        setCurrentUserData(tempUser)
      }
    });
      setIsEditing(false);
      };
  
      const onAddClick = () => {
        const newSubjects = [...subjects, { name: newSubject, price: newPrice }];
        setSubjects(newSubjects);
        setNewSubject('');
        setNewPrice('');
      };
  
      const handleCancelClick = () => {
        setIsEditing(false);
        setNewSubject('');
        setNewPrice('');
      }
  
      const handleDeleteClick = (index: number) => {
        const updatedSubjects = [...subjects];
        updatedSubjects.splice(index, 1);
        setSubjects(updatedSubjects);
      };
  
      return (
        <div className="p-4 max-w-xl mx-auto">
          {isEditing ? (
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">First Name:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
              />
              <label className="block text-sm font-bold mb-2">Last Name:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
              />
              <label className="block text-sm font-bold mb-2">Description:</label>
              <textarea
                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                value={newTutorDescription}
                onChange={(e) => setNewTutorDescription(e.target.value)}
              />
            </div>
          ) : (
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">First Name:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
                readOnly
              />
              <label className="block text-sm font-bold mb-2">Last Name:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
                readOnly
              />
              <label className="block text-sm font-bold mb-2">Description:</label>
              <textarea
                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                value={newTutorDescription}
                onChange={(e) => setNewTutorDescription(e.target.value)}
                readOnly
              />
          </div>
          
          )}
          <div className="text-center">
            <p className="mb-4 text-lg font-semibold">Your Subjects:</p>
          </div>
          {subjects.map((subject: any, index: any) => (
            <div key={index} className="mb-4">
              <div className="border border-gray-300 rounded-md p-2 flex justify-between items-center">
                <p className="text-base font-semibold">{subject.name}</p>
                <p className="text-base">Price: ${subject.price}</p>
                {isEditing && (
                  <input
                    type="number"
                    className="border border-gray-300 rounded-md p-2 w-16"
                    value={subject.price.toString()}
                    onChange={(e) => handleInputEdit(index, parseFloat(e.target.value))}
                  />
                )}
                {isEditing && (
                  <button className="text-red-500 hover:text-red-700" onClick={() => handleDeleteClick(index)}>
                    <DeleteIcon />
                  </button>
                )}
              </div>
            </div>
          ))}
      
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mb-4"
            onClick={isEditing ? handleDoneClick : onEditClick}
          >
            {isEditing ? "Done" : "Edit"}
          </button>
      
          {isEditing && (
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full mb-4"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          )}
      
          {isEditing && (
            <div className="flex gap-2">
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 w-1/2"
                placeholder="New Subject"
                value={newSubject}
                onChange={(e) => setNewSubject(e.target.value)}
              />
              <input
                type="number"
                className="border border-gray-300 rounded-md p-2 w-1/4"
                placeholder="New Price"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
              />
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/4"
                onClick={onAddClick}
              >
                Add
              </button>
            </div>
          )}
        </div>
      );
      
}

export default TutorSettings
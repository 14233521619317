import React from 'react';

interface StarRatingProps {
    rating: number;
    numberOfReviews: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, numberOfReviews }) => {
    const filledStars = Math.round(rating); // Round the rating to the nearest whole number
    const emptyStars = 5 - filledStars; // Calculate the number of empty stars

    return (
        <div className="flex items-center">
            {[...Array(filledStars)].map((_, index) => (
                <img key={index} src="/assets/icons/filled-star.png" alt="filled star" className="h-5 w-5" />
            ))}
            {[...Array(emptyStars)].map((_, index) => (
                <img key={index} src="/assets/icons/empty-star.png" alt="empty star" className="h-5 w-5" />
            ))}
            <span className="ml-1 text-sm text-gray-600">({numberOfReviews})</span>
        </div>
    );
};

export default StarRating;

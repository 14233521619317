import { useRouterContext, useUpdate } from "@refinedev/core";
import { Profile, Sessions, StudentSettings } from 'components';
import { useAppContext } from 'contexts/AppContext';
import { useState } from 'react';
import { Button } from 'components/ui/button';

const StudentDashboard = () => {
  // States brought in
  const { Link } = useRouterContext();
  const [showStudentProfile, setShowStudentProfile] = useState(true);
  const [showSessions, setShowSessions] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const { currentStudentData } = useAppContext();
  const { mutate } = useUpdate();
  console.log({currentStudentData});
  function StudentProfile() {
    return (
      <Profile
        type="My Student"
        firstName={currentStudentData?.User?.firstName}
        lastName={currentStudentData?.User?.lastName}
        email={currentStudentData?.User?.email}
        avatar={currentStudentData?.User?.avatar}
        balance={currentStudentData?.balance}
        isTutor={false}
        //subjects={currentTutorData.subjects}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 max-w-screen-xl">
      <nav className="bg-green-900 p-4 flex items-center">
        <div className="flex justify-between w-full">
          <div className="flex space-x-4 items-center mx-auto">
          <Button
          variant="outline"
          size="default"
          className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
          onClick={() => {
            setShowStudentProfile(true);
            setShowSettings(false);
            setShowSessions(false);
          }}
          disabled={false}
        >
          My Profile
        </Button>
        
        <Button
          variant="outline"
          size="default"
          className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
          onClick={() => {
            setShowSessions(true);
            setShowSettings(false);
            setShowStudentProfile(false);
          }}
          disabled={false}
        >
          Sessions
        </Button>

        <Button
          variant="outline"
          size="default"
          className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
          onClick={() => {
            setShowSettings(true);
            setShowSessions(false);
            setShowStudentProfile(false);
          }}
          disabled={false}
        >
          Settings
        </Button>
          </div>
        </div>
      </nav>

      <br />

      {/* Content */}
      {showStudentProfile && <StudentProfile />}
      {showSessions && <Sessions />}
      {showSettings && <StudentSettings />}
    </div>
  );
};

export default StudentDashboard;
